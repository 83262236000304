type AuthenticatedUser = {
    isAuthenticated: true,
    id: string
};

type UnauthenticatedUser = {
    isAuthenticated: false
};

type User = AuthenticatedUser | UnauthenticatedUser;

const defaultUser: UnauthenticatedUser = {
    isAuthenticated: false
};

export type {
    AuthenticatedUser,
    UnauthenticatedUser,
    User
};

export {
    defaultUser
};