const local = window.localStorage;

const keys = {
    signinEmail: 'signin.email'
};

export const localStorageService = {
    save<T>(key: keyof typeof keys, value: T) {
        local.setItem(keys[key], JSON.stringify(value));
    },
    get<T>(key: keyof typeof keys): T | null {
        const data = local.getItem(keys[key]);
        if (data == null) {
            return null;
        }

        return JSON.parse(data);
    },
    delete(key: keyof typeof keys) {
        local.removeItem(keys[key]);
    }
};
