import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinkComponent from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

import UserHeaderSection from './UserHeaderSection';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
}));

const Header = () => {
    const classes = useStyles();

    return (
        <AppBar className={classes.appBar} position="fixed">
            <Toolbar>
                <LinkComponent underline="none" className={classes.title} color="inherit" to="/" component={Link}>
                    <Typography variant="h6">Sports Scheduler</Typography>
                </LinkComponent>
                <UserHeaderSection />
            </Toolbar>
        </AppBar>
    );
};

export default Header;