import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useCallback, useState, useEffect } from 'react';

type Props = {
    open: boolean,
    name?: string,
    onCancel: () => void,
    onSubmit: (name: string) => void
};

const TeamDialog = ({ open, name, onCancel, onSubmit }: Props) => {
    const [inputName, setName] = useState(name || '');

    useEffect(() => {
        if (name) {
            setName(name);
        }
    }, [name]);

    const close = useCallback(() => {
        setName(name || '');
        onCancel();
    }, [setName, onCancel, name]);

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>{name ? 'Manage' : 'Create'} Team</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the team name below.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="team-name"
                    label="Team Name"
                    fullWidth
                    value={inputName}
                    onChange={e => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onSubmit(inputName)} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TeamDialog;