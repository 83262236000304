import { ReactNode, createContext, useEffect, useState, useContext } from 'react';
import { UserContext } from '.';
import { RootOrganization } from '../models';
import { Repository, noUserRepository } from '../repositories';

type PropTypes = {
    children: ReactNode
};

type ContextData = {
    organizations: RootOrganization[],
    repository: Repository<RootOrganization>
};

export const OrganizationsContext = createContext<ContextData>({
    organizations: [],
    repository: noUserRepository()
});

export const OrganizationsContextProvider = ({ children }: PropTypes) => {
    const [organizations, setOrganizations] = useState<RootOrganization[]>([]);
    const { repositories } = useContext(UserContext);
    useEffect(() => {
        return repositories.organizations.getAllAsSnapshot(snapshot => {
            setOrganizations(o => {
                const orgs = [...o];
                snapshot.docChanges().forEach(doc => {
                    switch(doc.type) {
                        case 'added': {
                            orgs.push(doc.doc.data());
                            break;
                        }
                        case 'removed': {
                            const indexToDelete = orgs.findIndex(org => org.id === doc.doc.id);
                            if (indexToDelete >= 0) {
                                orgs.splice(indexToDelete, 1);
                            }

                            break;
                        }
                        case 'modified': {
                            const indexToDelete = orgs.findIndex(org => org.id === doc.doc.id);
                            if (indexToDelete >= 0) {
                                orgs[indexToDelete] = doc.doc.data();
                            }

                            break;
                        }
                    }
                });
                return orgs;
            });
        });
    }, [repositories]);

    return (
        <OrganizationsContext.Provider value={{ organizations, repository: repositories.organizations }}>
            {children}
        </OrganizationsContext.Provider>
    );
};