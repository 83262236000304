import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from '../contexts';
import { location, auth, local } from '../services';
import { routes } from '../configuration';

const emailKey = 'signinEmail';

const EmailAuthPage = () => {
    const href = location.href();
    const { user } = useContext(UserContext);
    const [email, setEmail] = useState(local.get<string>(emailKey));
    const [verify, setVerify] = useState(email != null);
    useEffect(() => {
        if (auth.isSignInWithEmailLink(href) && email != null && verify) {
            setVerify(false);
            auth.signInWithEmailLink(email, href)
                .then(() => {
                    local.delete(emailKey);
                })
                .catch(err => {
                    console.log('Error while authenticating: ', err);
                });
        }
    }, [user, email, verify, href]);
    
    if (user.isAuthenticated) {
        return <Redirect to={routes.closeBrowser.route} />;
    }

    return (
        <div>
            <label>E-mail:</label>
            <input type="text" value={email || ''} onChange={e => setEmail(e.target.value)} />
            <button type="button" onClick={() => setVerify(true)}>Login</button>
        </div>
    );
};

export default EmailAuthPage;