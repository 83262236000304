import {
    Typography,
    Link
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '../configuration';

const CloseBrowserPage = () => (
    <Typography variant="h6">
        Thank you for signing in, you may close this tab or
        <Link component={RouterLink} to={routes.organizations.route}>get started now</Link>.
    </Typography>
);

export default CloseBrowserPage;