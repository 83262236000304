import { makeStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

import Header from './Header';
import { Routes, Navigation } from '../';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}));

const Layout = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Header />
            <Navigation />
            <main className={classes.content}>
                <Toolbar />
                <Routes />
            </main>
        </div>
    );
};

export default Layout;