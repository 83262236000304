import { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { OrganizationRoot } from '../components';
import { RouteParams } from '../configuration/routes';
import { OrganizationsContext } from '../contexts';
import { RootOrganization } from '../models';

type RouteData = RouteParams['organization'];

const OrganizationsEditPage = () => {
    const { params: { organizationId }} = useRouteMatch<RouteData>();
    const [organization, setOrganization] = useState<RootOrganization | undefined>();
    const [loading, setLoading] = useState(true);
    const { repository } = useContext(OrganizationsContext);

    useEffect(() => {
        if (organizationId === 'new') {
            setLoading(false);
            return;
        }

        setLoading(true);
        repository.getOne(organizationId).then(setOrganization).then(() => setLoading(false));
    }, [repository, organizationId, setOrganization, setLoading]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <OrganizationRoot
        onSave={repository.save}
        organization={organization}
        />
    );
};

export default OrganizationsEditPage;