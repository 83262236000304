import { useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { auth, local } from '../../services';
import { actionCodes } from '../../configuration';
import LoginActions from './LoginActions';

interface Props {
    open: boolean,
    handleClose: () => void
};

const loginOrRegisterText = 'To login or register, please enter your e-mail address. You will receive a link back to this website to finalize the login process.';
const emailSentText = 'Thank you, please check your e-mail. You may continue using the application in the meantime.';

const LoginDialog = ({ open, handleClose } : Props) => {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const submit = useCallback(() => {
        auth.sendSignInLinkToEmail(email, actionCodes.emailSignin)
        .then(() => {
            local.save('signinEmail', email);
        })
        .catch(err => console.log('Error occured when sending email: ', err));

        setEmailSent(true);
    }, [email]);
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="login-dialog-title">
        <DialogTitle id="login-dialog-title">Login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {emailSent ? emailSentText : loginOrRegisterText}
          </DialogContentText>
          {
              !emailSent &&
              <TextField
                autoFocus
                margin="dense"
                id="login-email"
                label="Email Address"
                type="email"
                onChange={e => setEmail(e.target.value)}
                fullWidth
              />
          }
        </DialogContent>
        <LoginActions emailSent={emailSent} onClose={handleClose} onSubmit={submit} />
      </Dialog>
    );
};

export default LoginDialog;