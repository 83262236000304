import { Switch, Route } from 'react-router';

import pages from '../../pages';

const Routes = () => (
    <Switch>
        {
            Object.entries(pages).map(([pageName, pageData]) => {
                console.log('Rendering page ', pageName);
                const Page = pageData.page;
                return (
                    <Route key={pageName} path={pageData.route} exact>
                        <Page />
                    </Route>
                );
            })
        }
    </Switch>
);

export default Routes;
