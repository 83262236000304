type Menu = {
    label: string
}

type Route = {
    route: string,
    disableNavigation?: boolean,
    menu?: Menu
}

type Routes = {
    [key: string]: Route
};

type RouteParams = {
    organization: {
        organizationId: string
    }
};

const routes: Routes = {
    emailAuth: {
        route: '/emailAuth'
    },
    home: {
        route: '/'
    },
    organizations: {
        route: '/organizations',
        menu: {
            label: 'Organizations'
        }
    },
    organization: {
        route: '/organizations/:organizationId'
    },
    schedules: {
        route: '/schedules',
        menu: {
            label: 'Schedules'
        }
    },
    closeBrowser: {
        route: '/close',
        disableNavigation: true
    }
};

export default routes;
export type {
    RouteParams
};