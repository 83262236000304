import { routes } from '../configuration';
import CloseBrowserPage from './CloseBrowserPage';
import EmailAuthPage from './EmailAuthPage';
import HomePage from './HomePage';
import OrganizationEditPage from './OrganizationEditPage';
import OrganizationsPage from './OrganizationsPage';
import SchedulesPage from './SchedulesPage';

const pages = {
    emailAuth: {
        page: EmailAuthPage,
        route: routes.emailAuth.route
    },
    home: {
        page: HomePage,
        route: routes.home.route
    },
    organizations: {
        page: OrganizationsPage,
        route: routes.organizations.route
    },
    organization: {
        page: OrganizationEditPage,
        route: routes.organization.route
    },
    schedules: {
        page: SchedulesPage,
        route: routes.schedules.route
    },
    closeBrowser: {
        page: CloseBrowserPage,
        route: routes.closeBrowser.route
    }
};

export default pages;