import { ComponentPropsWithoutRef, CSSProperties } from 'react';
import Button from '@material-ui/core/Button';

const ClickableText = ({ style = {}, children, ...props }: ComponentPropsWithoutRef<typeof Button>) => {
    const baseStyle: CSSProperties = {
        textTransform: 'none',
        ...style
    };

    return (
        <Button style={baseStyle} {...props}>
            {children}
        </Button>
    );
};

export default ClickableText;