import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

interface Props {
    emailSent: boolean,
    onClose: () => void,
    onSubmit: () => void
}

const LoginActions = ({ emailSent, onClose, onSubmit } : Props) => emailSent ? (
    <DialogActions>
        <Button onClick={onClose} color="primary">
            Close
        </Button>
    </DialogActions>
) : (
    <DialogActions>
        <Button onClick={onClose} color="primary">
            Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
            Login
        </Button>
    </DialogActions>
);

export default LoginActions;