import { useState, useContext, useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';

import { UserContext } from '../../contexts';
import { auth } from '../../services';
import { LoginDialog } from '../';

const UserHeaderSection = () => {
    const { user } = useContext(UserContext);
    const [modalOpen, setModalOpen] = useState(false);

    const onClick = useCallback(() => {
        if (user.isAuthenticated) {
            auth.signOut();
        } else {
            setModalOpen(true);
        }
    }, [user.isAuthenticated]);

    const label = useMemo(() => user.isAuthenticated ? 'Logout' : 'Login', [user.isAuthenticated]);

    return (
        <>
        <Button color="inherit" onClick={onClick}>{label}</Button>
        <LoginDialog open={modalOpen} handleClose={() => setModalOpen(false)} />
        </>
    );
};

export default UserHeaderSection;
