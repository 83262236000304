import { useMemo, useCallback, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';

import { isTeamOrganization, Organization } from '../../models';
import OrganizationDialog from './OrganizationDialog';
import TeamDialog from './TeamDialog';

type EventData = Organization | string;
type Props = {
    organization: Organization,
    layerName: string,
    subLayerName: string,
    onAdded: (event: EventData) => void,
    onDeleted: (index: number) => void,
    onChanged: (index: number, event: EventData) => void,
    onSelected: (index: number) => void,
    onNameChanged: (name: string) => void
};

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 450,
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    button: {
        marginTop: 15,
        marginBottom: 15
    }
}));

const OrganizationLayer = ({ organization, layerName, subLayerName, onNameChanged, onAdded, onDeleted, onSelected }: Props) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [teamName, setTeamName] = useState('');
    const isTeamOrg = useMemo(() => isTeamOrganization(organization), [organization]);
    const getText = useCallback((group: EventData): string => {
        if (isTeamOrg) {
            return group as string;
        } else {
            return (group as Organization).name;
        }
    }, [isTeamOrg]);

    const addOrg = useCallback((data: EventData) => {
        setDialogOpen(false);
        onAdded(data);
    }, [setDialogOpen, onAdded]);

    const select = useCallback((idx: number) => {
        if (isTeamOrg) {
            setTeamName(organization.groups[idx] as string);
            setDialogOpen(true);
        } else {
            onSelected(idx);
        }
    }, [isTeamOrg, setTeamName, setDialogOpen, organization, onSelected]);

    return (
        <div className={classes.container}>
            <TextField value={organization.name} onChange={e => onNameChanged(e.target.value)} label="Name" placeholder={`My ${layerName}`} variant="outlined" />
            <Button className={classes.button} type="button" variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>
                Add {subLayerName} to {organization.name || layerName}
            </Button>
            <List>
                {
                    organization.groups.map((g, idx) => (
                        <Fragment key={getText(g)}>
                            <Divider />
                            <ListItem button onClick={() => select(idx)}>
                                <ListItemText>{getText(g)}</ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => onDeleted(idx)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Fragment>
                    ))
                }
            </List>
            <OrganizationDialog
            open={dialogOpen && !isTeamOrg}
            onCancel={() => setDialogOpen(false)}
            onComplete={addOrg}
            layerName={subLayerName}
            />
            <TeamDialog
            open={dialogOpen && isTeamOrg}
            onCancel={() => setDialogOpen(false)}
            onSubmit={addOrg}
            name={teamName}
            />
        </div>
    );
};

export default OrganizationLayer;

export type {
    EventData
};