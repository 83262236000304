import { ReactNode, createContext, useEffect, useState, useMemo } from 'react';

import { auth } from '../services';
import { defaultUser, User } from '../models/user';
import { getRepositories } from '../repositories';

type UserContextData = {
    user: User,
    repositories: ReturnType<typeof getRepositories>
};

type PropTypes = {
    children: ReactNode
};

export const UserContext = createContext<UserContextData>({ user: defaultUser, repositories: getRepositories() });

export const UserContextProvider = ({ children }: PropTypes) => {
    const [user, setUser] = useState<User>(defaultUser);
    const repositories = useMemo(() => getRepositories(user.isAuthenticated ? user.id : undefined), [user]);
    useEffect(() => {
        console.log('Awaiting auth state changes...', auth.currentUser);
        auth.onAuthStateChanged(result => {
            console.log('Authentication state changed: ', result);
            if (result) {
                setUser({ isAuthenticated: true, id: result.uid });
            } else {
                setUser(defaultUser);
            }
        });
    }, []);

    return (
        <UserContext.Provider value={{ user, repositories }}>
            {children}
        </UserContext.Provider>
    );
};