import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { ListItemText, makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItemLink from '../material/ListItemLink';

import { routes } from '../../configuration';

const navigationWidth = 240;
const routeValues = Object.values(routes);
const menuItems = routeValues.filter(r => r.menu);
const useStyles = makeStyles(() => ({
    drawer: {
        width: navigationWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: navigationWidth
    }
}));

const disableRoutes = routeValues.filter(x => x.disableNavigation);

const Navigation = () => {
    const location = useLocation();
    const disableNavigation = useMemo(() => disableRoutes.some(r => location.pathname === r.route), [location.pathname]);
    const classes = useStyles();

    if (disableNavigation) {
        return null;
    }

    return (
        <Drawer variant="permanent" anchor="left" open className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
            <Toolbar />
            <List>
                {
                    menuItems.map(menuItem => (
                        <ListItemLink key={menuItem.menu!.label} to={menuItem.route}>
                            <ListItemText>{menuItem.menu!.label}</ListItemText>
                        </ListItemLink>
                    ))
                }
            </List>
        </Drawer>
    );
};

export default Navigation;