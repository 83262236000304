import { throwError } from '../helpers';

type BaseOrganization = {
    id?: string,
    name: string,
    owner?: string
};

type TeamOrganization = BaseOrganization & {
    groups: string[],
    type: 'team'
};

type LayeredOrganization = BaseOrganization & {
    groups: Organization[],
    type: 'layer'
};

type RootOrganization = LayeredOrganization & {
    layerNames: string[]
};

type Organization = TeamOrganization | LayeredOrganization | RootOrganization;

function isTeamOrganization(org: Organization): org is TeamOrganization {
    return org.type === 'team';
}

function isLayeredOrganization(org: Organization): org is LayeredOrganization {
    return !isTeamOrganization(org);
}

function isRootOrganization(org: Organization): org is RootOrganization {
    return typeof (org as RootOrganization).layerNames !== 'undefined';
}

const forEachTeamInOrg = (org: Organization, cb: (teamId: string, path: number[]) => void, path: number[] = [0]) => {
    if (isTeamOrganization(org)) {
        org.groups.forEach((team, index) => {
            path.push(index);
            cb(team, path);
            path.pop();
        });
    } else {
        org.groups.forEach((group, index) => {
            path.push(index);
            forEachTeamInOrg(group, cb, path);
            path.pop();
        });
    }
};

const getOrgFromPath = (org: Organization, path: number[]): Organization => {
    let currentOrg = org;
    let pathIndex = 0;
    while (pathIndex < path.length) {
        if (isLayeredOrganization(currentOrg) && path[pathIndex] < org.groups.length) {
            currentOrg = currentOrg.groups[path[pathIndex]];
            pathIndex++;
        } else {
            throwError('Path references organization that does not exist', org, path);
        }
    }

    return currentOrg;
};

const clone = <T extends Organization>(org: T): T => {
    if (isTeamOrganization(org)) {
        return {
            ...org,
            groups: [...org.groups]
        };
    }

    return {
        ...org,
        groups: org.groups.map(clone)
    };
};

const immutableUpdate = <T extends Organization>(org: T, path: number[], action: (o: Organization) => void): T => {
    const copy = clone(org);
    const orgToUpdate = getOrgFromPath(copy, path);

    action(orgToUpdate);
    return copy;
};

const getDepthForTeams = (org: RootOrganization): number => {
    return org.layerNames.length;
};

const validationMessage = (code: string, message: string) => ({ code: `organization.${code}`, message });
type ValidationMessage = ReturnType<typeof validationMessage>;

const validate = (org: Organization): ValidationMessage[] => {
    const validations = [];
    
    if (!org.name) {
        validations.push(validationMessage('name.empty', 'An organization is missing a name.'));
    }

    if (!org.groups.length) {
        validations.push(validationMessage('groups.empty', `Organization ${org.name} is missing ${isTeamOrganization(org) ? 'teams' : 'sub-organizations'}.`));
    }

    if (isTeamOrganization(org)) {
        if (org.groups.some(g => !g)) {
            validations.push(validationMessage('groups.contains-empty', `Organization ${org.name} has a team with no name.`));
        }
    } else {
        validations.push(...org.groups.flatMap(validate));
    }

    return validations;
};

export type {
    Organization,
    LayeredOrganization,
    TeamOrganization,
    RootOrganization,
    ValidationMessage
};

export {
    forEachTeamInOrg,
    getOrgFromPath,
    immutableUpdate,
    isTeamOrganization,
    isLayeredOrganization,
    isRootOrganization,
    getDepthForTeams,
    validate
};