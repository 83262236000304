import firebase from 'firebase/app';
import { firestore } from './services';
import { RootOrganization, Schedule } from './models';
import { throwError } from './helpers';

interface Entity {
    id?: string,
    owner?: string
}

type Repository<T extends Entity> = {
    getAll(mixin?: (db: firebase.firestore.Query<T>) => firebase.firestore.Query<T>): Promise<T[]>,
    getAllAsSnapshot(onNext: (snapshot: firebase.firestore.QuerySnapshot<T>) => void, mixin?: (db: firebase.firestore.Query<T>) => firebase.firestore.Query<T>): () => void,
    getOne(id: string): Promise<T | undefined>,
    save(data: T): Promise<string>,
    delete(dataOrId: string | T): Promise<string>
    getCollection(): firebase.firestore.CollectionReference
};

const baseConverter = <T extends Entity>() => (currentUser: string): firebase.firestore.FirestoreDataConverter<T> => ({
    toFirestore(data: T) {
        return {
            ...data,
            owner: currentUser
        };
    },
    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<T>) {
        const data = snapshot.data();
        data.id = snapshot.id;

        return data;
    }
});

const noUserRepository = <T extends Entity>(): Repository<T> => ({
    getAll() { return Promise.resolve([]); },
    getAllAsSnapshot() { return () => {}; },
    getOne() { return Promise.resolve(undefined); },
    save() { return throwError('No user currently set.'); },
    delete() { return throwError('No user currently set.'); },
    getCollection() { return firestore.collection('to-be-deleted'); }
});

const repository = <T extends Entity>(collectionName: string, converter = baseConverter<T>()) => (currentUserId?: string): Repository<T> => {
    if (!currentUserId) {
        return noUserRepository();
    }

    const getCollection = () => firestore.collection(collectionName).withConverter<T>(converter(currentUserId));
    return {
        getAll(mixin?: (db: firebase.firestore.Query<T>) => firebase.firestore.Query<T>) {
            let collection = getCollection().where('owner', '==', currentUserId);
            if (mixin) {
                collection = mixin(collection);
            }

            return collection.get().then(snapshot => snapshot.docs.map(doc => doc.data()));
        },
        getAllAsSnapshot(onNext: (snapshot: firebase.firestore.QuerySnapshot<T>) => void, mixin?: (db: firebase.firestore.Query<T>) => firebase.firestore.Query<T>) {
            let collection = getCollection().where('owner', '==', currentUserId);
            if (mixin) {
                collection = mixin(collection);
            }

            return collection.onSnapshot(onNext);
        },
        getOne(id: string) {
            return getCollection().doc(id).get().then(snapshot => snapshot.data());
        },
        save(data: T) {
            data.owner = currentUserId;
            const docRef = getCollection().doc(data.id);
            return docRef.set(data).then(() => docRef.id);
        },
        delete(dataOrId: string | T) {
            const id = typeof dataOrId === 'string' ? dataOrId : dataOrId.id;
            if (!id) {
                return throwError('No identifier provided for deletion: ', dataOrId);
            }

            return getCollection().doc(id).delete().then(() => id);
        },
        getCollection
    };
};

const organizations = repository<RootOrganization>('organizations');
const schedules = repository<Schedule>('schedules');

const getRepositories = (currentUserId?: string) => ({
    organizations: organizations(currentUserId),
    schedules: schedules(currentUserId)
});

export type {
    Repository
};

export {
    getRepositories,
    noUserRepository
};