import application from './application';
import routes from './routes';

const emailSignin = {
    url: `${application.baseUrl}${routes.emailAuth.route}`,
    handleCodeInApp: true
};

export {
    emailSignin
};