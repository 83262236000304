import { useMemo, forwardRef, ReactNode } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';

type PropTypes = {
  children: ReactNode,
  to: string
};

const ListItemLink = ({ children, to }: PropTypes) => {
    const renderLink = useMemo(
      () => forwardRef<HTMLAnchorElement>((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
      [to]
    );
  
    return (
      <ListItem button component={renderLink}>
        {children}
      </ListItem>
    );
};

export default ListItemLink;