import { useState, useCallback, useMemo } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import OrganizationLayer, { EventData } from './OrganizationLayer';
import { Organization, getOrgFromPath, immutableUpdate, isTeamOrganization, isLayeredOrganization, LayeredOrganization, RootOrganization } from '../../models';
import { switchLast } from '../../helpers';
import ClickableText from '../material/ClickableText';
import { makeStyles } from '@material-ui/core';

interface Breadcrumb {
    label: string,
    indexFromParent?: number
};

const defaultOrganization: RootOrganization = {
    name: '',
    type: 'layer',
    groups: [],
    layerNames: []
};

const getAddToOrgAction = (evt: EventData) => (org: Organization) => {
    console.log('Adding ', evt, ' to the organization ', org);
    if (typeof evt === 'string') {
        // Must be a team organization
        if (!isTeamOrganization(org)) {
            throw new Error('Tried to add a team to a layered organization');
        }

        org.groups.push(evt);
    } else {
        // Must be a layered organization
        if (!isLayeredOrganization(org)) {
            throw new Error('Tried to add a team to a layered organization');
        }

        org.groups.push(evt);
    }
};

const getDeleteFromOrgAction = (index: number) => (org: Organization) => {
    org.groups.splice(index, 1);
};

const getOrganizationLayerName = (root: RootOrganization, depth: number) => {
    if (root.layerNames.length <= depth) {
        return 'team';
    }

    return root.layerNames[depth];
};

const useStyles = makeStyles(() => ({
    breadcrumbs: {
        marginBottom: 15
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch'
    }
}));

type Props = {
    organization?: RootOrganization,
    onSave(org: RootOrganization): Promise<string>
};

const OrganizationRoot = ({ organization, onSave }: Props) => {
    const classes = useStyles();
    const [rootOrganization, setRootOrganization] = useState<RootOrganization>(organization || defaultOrganization);
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([{ label: rootOrganization.name }]);

    const selectBreadcrumb = useCallback((breadcrumbIndex: number) => {
        setBreadcrumbs(breadcrumbs.slice(0, breadcrumbIndex + 1));
    }, [breadcrumbs]);

    const breadcrumbButton = useCallback((bc: Breadcrumb, index: number) => (
        <ClickableText key={index} onClick={() => selectBreadcrumb(index)}>
            {bc.label}
        </ClickableText>
    ), [selectBreadcrumb]);

    const breadcrumbText = useCallback((bc: Breadcrumb) => (
        <Typography key="current-selection">{bc.label}</Typography>
    ), []);

    const path = useMemo(() => breadcrumbs.slice(1).map(bc => bc.indexFromParent!), [breadcrumbs]);
    const selectedOrganization = useMemo(() => getOrgFromPath(rootOrganization, path), [rootOrganization, path]);
    
    const updateOrganizationName = useCallback((name: string) => {
        setRootOrganization(immutableUpdate(rootOrganization, path, org => org.name = name));
        setBreadcrumbs(bc => [...bc.slice(0, bc.length - 1), { ...bc[bc.length - 1], label: name }]);
    }, [setRootOrganization, rootOrganization, path]);

    const save = useCallback(() => onSave(rootOrganization), [onSave, rootOrganization]);

    return (
        <div>
            <div className={classes.topContainer}>
                <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
                    {switchLast(breadcrumbs, breadcrumbButton, breadcrumbText)}
                </Breadcrumbs>
                <Button color="primary" variant="contained" type="button" onClick={save}>Save {rootOrganization.name} organization</Button>
            </div>
            <OrganizationLayer organization={selectedOrganization}
            layerName={getOrganizationLayerName(rootOrganization, path.length)}
            subLayerName={getOrganizationLayerName(rootOrganization, path.length + 1)}
            onAdded={e => setRootOrganization(immutableUpdate(rootOrganization, path, getAddToOrgAction(e)))}
            onDeleted={idx => setRootOrganization(immutableUpdate(rootOrganization, path, getDeleteFromOrgAction(idx)))}
            onChanged={() => {}}
            onSelected={idx => setBreadcrumbs([...breadcrumbs, { label: (selectedOrganization as LayeredOrganization).groups[idx].name, indexFromParent: idx }])}
            onNameChanged={updateOrganizationName}
            />
        </div>
    );
};

export default OrganizationRoot;