const firebaseConfig = {
  apiKey: 'AIzaSyBHX9UhJuQGTYSSI2Fxiq4Q262woVau9bE',
  authDomain: 'razz-apps.firebaseapp.com',
  projectId: 'razz-apps',
  storageBucket: 'razz-apps.appspot.com',
  messagingSenderId: '455090021042',
  appId: '1:455090021042:web:01c96fd443d3cf98ac9fab',
  measurementId: 'G-BC0RSDD9LS'
};

export default firebaseConfig;
