import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useCallback } from 'react';
import { useState } from 'react';
import { LayeredOrganization } from '../../models';

type Props = {
    open: boolean,
    layerName: string,
    onCancel: () => void,
    onComplete: (org: LayeredOrganization) => void
};

const getLayeredOrganization = (name: string): LayeredOrganization => ({
    name,
    type: 'layer',
    groups: []
});

const OrganizationDialog = ({ onComplete, layerName, open, onCancel }: Props) => {
    const [name, setName] = useState('');

    const saveOrganization = useCallback(() => {
        onComplete(getLayeredOrganization(name));

        setName('');
    }, [name, onComplete]);

    const close = useCallback(() => {
        setName('');
        onCancel();
    }, [setName, onCancel]);

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>Create {layerName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the {layerName} name below.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="org-name"
                    label={`${layerName} name`}
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={saveOrganization} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrganizationDialog;