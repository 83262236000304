import { Button } from '@material-ui/core';
import { useState } from 'react';

import NewScheduleDialog from '../components/schedules/NewScheduleDialog';

const SchedulesPage = () => {
    const [newScheduleOpen, setNewScheduleOpen] = useState(false);
    return (
        <div>
            <Button type="button" variant="contained" onClick={() => setNewScheduleOpen(true)}>Create New Schedule</Button>
            <NewScheduleDialog open={newScheduleOpen} onCancel={() => setNewScheduleOpen(false)} />
        </div>
    );
};

export default SchedulesPage;