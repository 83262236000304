import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';

import { OrganizationsContext } from '../../contexts';
import { zip } from '../../helpers';
import generator from '../../services/generator';

type Props = {
    open: boolean,
    onCancel: () => void
};

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 10
    }
}));

const NewScheduleDialog = ({ open, onCancel }: Props) => {
    const classes = useStyles();
    const { organizations } = useContext(OrganizationsContext);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>('');
    const reset = useCallback(() => setSelectedOrganizationId(''), [setSelectedOrganizationId]);
    const [generating, setGenerating] = useState(false);
    const cancel = useCallback(() => { reset(); onCancel(); }, [reset, onCancel]);

    const organizationOptions = useMemo(() => organizations.map(org => ({ id: org.id!, name: org.name })), [organizations]);
    const selectedOrganization = useMemo(() => organizations.find(org => org.id === selectedOrganizationId), [organizations, selectedOrganizationId]);
    const [layerDetails, setLayerDetails] = useState<number[]>([]);

    useEffect(() => {
        setLayerDetails(selectedOrganization?.layerNames.map(() => 0) || []);
    }, [selectedOrganization]);

    const onLayerChange = useCallback((idx: number, value: string) => 
        setLayerDetails(layers => [...layers.slice(0, idx), parseInt(value, 10), ...layers.slice(idx + 1)]),
        [setLayerDetails]
    );

    const generateSchedule = useCallback(() => {
        setGenerating(true);
        new Promise(resolve => setTimeout(() => resolve(generator.generateSchedule({ numberOfGamesInLayer: layerDetails, gamesInSeries: 1 }, selectedOrganization!)), 4000))
            .then(console.log)
            .then(cancel);
    }, [setGenerating, selectedOrganization, layerDetails, cancel]);

    return (
        <Dialog open={open} onClose={cancel} fullWidth>
            <Backdrop open={generating} className={classes.backdrop}>
                <CircularProgress />
            </Backdrop>
            <DialogTitle>Create New Schedule</DialogTitle>
            <DialogContent>
                <FormControl className={classes.select}>
                    <InputLabel id="new-schedule-organization-select-label">Organization</InputLabel>
                    <Select
                    id="new-schedule-organization-select"
                    labelId="new-schedule-organization-select-label"
                    value={selectedOrganizationId}
                    onChange={e => setSelectedOrganizationId(e.target.value as string)}
                    >
                    {
                        organizationOptions.map(option => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)
                    }
                    </Select>
                    {
                        selectedOrganization && zip(selectedOrganization.layerNames, layerDetails).map(([name, details], index) => <TextField key={name} type="number" fullWidth label={`Games within the same ${name}`} placeholder="0" value={details || ''} onChange={e => onLayerChange(index, e.target.value)}/>)
                    }
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button disabled={!selectedOrganizationId || layerDetails.every(x => !x)} onClick={generateSchedule} color="primary">
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewScheduleDialog;