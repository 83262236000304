let nextDouble = Math.random;

const random = {
    randomInt(min: number, max: number | null = null) {
        if (max === null) {
            max = min;
            min = 0;
        }

        return Math.floor(nextDouble() * (max - min)) + min;
    },
    randomElement<T>(elements: T[]) {
        return elements[this.randomInt(elements.length)];
    },
    shuffle<T>(elements: T[]) {
        const copy = elements.slice();
        const buffer: T[] = [];

        while (copy.length) {
            const index = this.randomInt(copy.length);
            buffer.push(copy[index]);
            copy.splice(index, 1);
        }

        return buffer;
    }
};

const setNextDoubleFunc = (func: () => number) => {
    nextDouble = func;
};

export default random;
export {
    setNextDoubleFunc
};
