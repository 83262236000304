import { throwError } from '.';

function repeat<T>(obj: T, times: number) {
    const data: T[] = [];
    while (data.length < times) {
        data.push(obj);
    }

    return data;
};

function switchLast<K,V>(arr: K[], mapMethod: (val: K, idx: number, arr: K[]) => V, lastMapMethod: (val: K, idx: number, arr: K[]) => V): V[] {
    return arr.map((value, index, array) => {
        if (index === arr.length - 1) {
            return lastMapMethod(value, index, array);
        } else {
            return mapMethod(value, index, array);
        }
    });
};

function replace<T>(arr: T[], index: number, replacement: T): T[] {
    if (index < 0 || index >= arr.length) {
        throwError('Invalid replace index.', arr, index);
    }
    
    return [...arr.slice(0, index), replacement, ...arr.slice(index + 1)];
};

function zip<T,U>(arr1: T[], arr2: U[]): [T, U][] {
    return arr1.slice(0, Math.min(arr1.length, arr2.length)).map((el, idx) => [el, arr2[idx]]);
}

export {
    repeat,
    switchLast,
    replace,
    zip
};