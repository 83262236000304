import { Button, IconButton, List, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ListItemLink from '../components/material/ListItemLink';
import { OrganizationsContext } from '../contexts';
import { RootOrganization } from '../models';

const createLinkUrl = (id?: string) => `organizations/${id}`;

const OrganizationsPage = () => {
    const { organizations, repository } = useContext(OrganizationsContext);
    const deleteOrg = useCallback((org: RootOrganization) => repository.delete(org), [repository]);

    return (
        <div>
            <Button color="primary" variant="contained" component={Link} to={createLinkUrl('new')}>Create New Organization</Button>
            <List>
            {
                organizations.map(org => (
                    <ListItemLink key={org.id} to={createLinkUrl(org.id)}>
                        <ListItemText>{org.name}</ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => deleteOrg(org)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItemLink>
                ))
            }
            </List>
        </div>
    );
};

export default OrganizationsPage;