import { GenerationConfiguration } from '../models/generationConfiguration';
import { Game } from '../models/game';
import { Organization, forEachTeamInOrg } from '../models/organization';
import random from './random';
import { repeat } from '../helpers';

type OrgTeam = {
    id: string,
    layers: number[]
};

type ConfiguredSchedule = {
    games: Game[]
};

const getMatchingLayer = (layers1: number[], layers2: number[]): number => {
    const index = layers1.map((layer, index) => ({ layer, index })).findIndex(data => layers2[data.index] !== data.layer) - 1;

    if (index < 0) {
        throw new Error(`Could not determine match between layers ${layers1} and ${layers2}`);
    }

    return index;
};

const generator = {
    generateSchedule(config: GenerationConfiguration, organization: Organization): ConfiguredSchedule {
        const allTeams: OrgTeam[] = [];

        forEachTeamInOrg(organization, (id, layers) => {
            allTeams.push({
                id,
                layers: layers.slice()
            });
        });

        const allGames: Game[] = [];
        allTeams.forEach((home, idx) => {
            allTeams.slice(idx + 1).forEach(away => {
                const game: Game = {
                    homeTeamId: home.id,
                    awayTeamId: away.id,
                    result: null
                };

                const matchingLayerIndex = getMatchingLayer(home.layers, away.layers);
                allGames.push(...repeat(game, config.numberOfGamesInLayer[matchingLayerIndex]));
            });
        });

        const shuffledGames = random.shuffle(allGames);

        return {
            games: shuffledGames
        };
    }
};

export default generator;
export {
    getMatchingLayer
};
export type {
    OrgTeam,
    ConfiguredSchedule
};