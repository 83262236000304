const normalize = (key: string) => `REACT_APP_${key.toUpperCase().replace(/\./g, '_')}`;
const exists = (key: string) => typeof process.env[key] === 'string';
const getValue = (key: string, defaultValue: string | null = null) => {
    key = normalize(key);
    return exists(key) ? process.env[key] as string : defaultValue;
};
const getRequiredValue = (key: string) => {
    const val = getValue(key);
    if (val == null) {
        throw new Error(`Missing required configuration value: ${key}`);
    }

    return val;
};

export {
    getValue,
    getRequiredValue
};